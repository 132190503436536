require("jquery");
window.jQuery = $;
window.$ = $;
require("packs/webflow");
require("packs/jquery.validate.min.js");
require("controllers")


$.extend($.validator.messages, {
	required: "Dieses Feld ist ein Pflichtfeld.",
	maxlength: $.validator.format("Gib bitte maximal {0} Zeichen ein."),
	minlength: $.validator.format("Gib bitte mindestens {0} Zeichen ein."),
	rangelength: $.validator.format("Gib bitte mindestens {0} und maximal {1} Zeichen ein."),
	email: "Gib bitte eine gültige E-Mail Adresse ein.",
	url: "Gib bitte eine gültige URL ein.",
	remote: "Dieser Einladungscode wurde nicht gefunden.",
	date: "Gib bitte ein gültiges Datum ein.",
	number: "Gib bitte eine Nummer ein.",
	digits: "Gib bitte nur Ziffern ein.",
	equalTo: "Deine Passwörter stimmen nicht überein.",
	range: $.validator.format("Gib bitte einen Wert zwischen {0} und {1} ein."),
	max: $.validator.format("Gib bitte bitte einen Wert kleiner oder gleich {0} ein."),
	min: $.validator.format("Gib bitte bitte einen Wert größer oder gleich {0} ein."),
	creditcard: "Gib bitte eine gültige Kreditkarten-Nummer ein."
});

$(function() {


	window.cookieconsent.initialise({
	    "palette": {
	      "popup": {
	        "background": "#00b978"
	      },
	      "button": {
	        "background": "#005eff"
	      }
	    },
	    "type": "opt-out",
	    "revokable": false,
	    "law": {
	     "regionalLaw": false,
	    },
	    "content": {
	      "message": "Diese Website verwendet für das bestmögliche Nutzererlebnis Cookies. ",
	      "dismiss": "Cookies erlauben",
	      "allow": "Cookies erlauben",
	      "deny": "Cookies ablehnen",
	      "link": "Mehr erfahren",
	      "policy": ""
	    }
	  });


	$(".cc-revoke").css("display", "none");


	 $.validator.addMethod(
        "notNullAmount", 
        function(value, element) {
            return !(value == "0,00");
        },
        "Der Einkaufswert muss größer als 0 Euro sein."
	);

	//VALIDATE the purchse value submit form 
	$('#validate_purchase_form').validate({
	                rules: {
	                    "purchase[invoice_id]": {
	                        minlength: 2,
	                        required: true,
	                    },
	                    "purchase[amount]": {
	                        minlength: 2,
	                        required: true,
	                        notNullAmount: true,
	                        maxlength: 7
	                    }
	                },
	                messages: {
	                    "purchase[amount]": {
	                        maxlength: "Der Einkaufswert darf nicht größer als vierstellig sein.",
	                    }
	                },

	                invalidHandler: function (event, validator) { 
	                  
	                },

	                errorPlacement: function (label, element) { // render error placement for each input type   
	               	

	                 var $parent = $(element).parents('.form_group');
	                 var $warning = $parent.children('.error_label');
	                 $warning.text(label.text());
	        
	             

	                
	                },

	                success: function (label, element) {        
	        
	                    var $parent = $(element).parents('.form_group');
	                    var $warning = $parent.children('.error_label');   
	                    
	                }
	    }); 


	//Validate the SIGN UP FORM
	$('#validate_form').validate({
                rules: {
                    "user[firstname]": {
                        minlength: 2,
                        required: true,
                    },
                    "user[lastname]": {
                        minlength: 2,
                        required: true,
                    },
                    "user[password]": {
                        minlength: 6,
                        required: true,
                    },
                    "invite[invite_token]": {
                        remote: "/users/validate_invite_token",
                        minlength: 0
                    },
                    "checkbox":
                    {  
                        required: true
                    }
                },
                messages: {
                    "checkbox": {
                        required: "Ohne unsere AGB und unsere Datenschutzerklärung zu akzeptieren, kannst Du nicht Mitglied werden.",
                    }
                },

                invalidHandler: function (event, validator) {            
                   
                },

                errorPlacement: function (label, element) { // render error placement for each input type   
               

                 var $parent = $(element).parents('.form_group');
                 var $warning = $parent.children('.error_label');
                 $warning.text(label.text());
        
                
                },

                success: function (label, element) {        
        
                    var $parent = $(element).parents('.form_group');
                    var $warning = $parent.children('.error_label');   
                    
                }
            }); 


	//Toggle the password field
	$("#password_reveal").click(function(){
    var label = $(this).children(".password_label");
    var x = document.getElementById("user_password");
      if (x.type === "password") {
        label.text("Passwort verbergen");
        x.type = "text";
      } else {
        label.text("Passwort anzeigen");
        x.type = "password";
      }
	});

	$("#toggle_invite").click(function(){
	    $(this).hide();
	    $("#invite_group").removeClass("hidden_form_group");
	});
});
