import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
export default class extends Controller {


	static targets = ["pagination"]

	initialize() {
	  	console.log("Stimulus");
	  	let options = {
	      rootMargin: '0px',
	    }

	    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
	 }


	connect() {
		console.log("Search controller");
	    this.intersectionObserver.observe(this.paginationTarget)
	    this.currentPage = 1;
	    this.sort = "popularity"
	}

	disconnect() {
	    this.intersectionObserver.unobserve(this.paginationTarget)
	    this.currentPage = 1;
	  }

	processIntersectionEntries(entries) {
	    entries.forEach(entry => {
	      if (entry.isIntersecting && document.getElementsByClassName("shop_card").length > 0) {
	        console.log("Load more",document.getElementsByClassName("shop_card").length);
	        console.log("Page",this.currentPage);
	        this.load_shops()
	      }
	    })
	}

	query_changed(event){
		this.search_shops()
	}

	sort_changed(event){
		console.log("Sort change",event);
		console.log("Sort change",event.target.value);
		this.sort = event.target.value;
		this.search_shops()

	}

	search_shops()
	{
		this.currentPage = 1;
		self = this;
		let query = document.getElementById("search").value;
		console.log("Query",query);

		Rails.ajax({
	      type: "get",
	      url: "/search",
	      data: `query=${query}&page=${this.currentPage}&sort=${this.sort}`,
	      dataType: "json",
	      success: function(data) {
		    console.log(data);
		    document.getElementById("results").innerHTML = data["html_content"];
		    document.getElementById("result_counter").innerHTML = data["shop_count"];
		   
		  }.bind(this),
		  error: function(data)
		  {
		  	console.log("Something went wrong!");
		  }
	    });
	}

	load_shops()
	{
		console.log("Load shops: Page", this.currentPage);
		let query = document.getElementById("search").value;
		
		//Load more shops for page one makes no sense, automatically switch to page 2
		if(this.currentPage == 1)
		{
			this.currentPage += 1;
		}

		document.getElementById("load-more").innerHTML = "Weitere Partnershops laden...";
		Rails.ajax({
	      type: "get",
	      url: "/search",
	      data: `query=${query}&page=${this.currentPage}&sort=${this.sort}`,
	      dataType: "json",
	      success: function(data) {
		    console.log(data);
		    console.log(data["html_content"]);
		    document.getElementById("results").innerHTML += data["html_content"];
		    this.currentPage += 1;
		    console.log("Increment page", this.currentPage);
		   	
		    document.getElementById("load-more").innerHTML = "";
		    
		  }.bind(this),
		  error: function(data)
		  {
		  	console.log("Something went wrong!");
		  }
	    });
	}



}